@import "~antd/dist/antd.css";

@font-face {
  font-family: "Amazon Ember Regular";
  src: url("./webfontkit/amazon-ember.eot");
  /* IE9*/
  src: url("./webfontkit/amazon-ember.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./webfontkit/amazon-ember.woff2") format("woff2"),
    /* chrome、firefox */
    url("./webfontkit/amazon-ember.woff") format("woff"),
    /* chrome、firefox */
    url("./webfontkit/amazon-ember.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("./webfontkit/amazon-ember.svg#Amazon Ember Regular") format("svg");
  /* iOS 4.1- */
}

@media print {

  html,
  body {
    /* height: 100vh; */
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    /* overflow: hidden; */
  }

}

body {
  font-family: "Amazon Ember Regular" !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-layout-header {
  background: #fff;
  padding: 0px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-menu-horizontal>.ant-menu-item a {
  color: #fff;
}

/* 

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #CECE5D;
}
.ant-menu-item .anticon, .ant-menu-submenu-title-seleted .anticon {
  color: #CECE5D;
} */
.hidden-element .ant-form-item-control-input {
  display: none;
}

.flexItem {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid gray;
}

.flex {
  display: flex;
  justify-content: space-between;
  padding: 15px;

}

.divide {
  padding: 3px;
  margin: 3px;
}

.ck.ck-editor__editable_inline>:last-child {
  height: 180px
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

iframe {
  pointer-events: none;
}

.productDropDown {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border: none;
  background: none;
  color: rgb(22, 22, 22);
}

.printIcon {
  display: flex;
  justify-content: space-between;
  height: 50px;
  background: #003366;
  color: #FFF;
  padding: 12px 17px 0 17px;
}


div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu .categoryButton {
  display: inline-block;
  color: white;
  background: #003366;
  text-align: center;
  margin-right: 11px;
  margin-bottom: 11px;
  text-decoration: none;
}

.themeBtn {
  background: #003366;
  color: #FFF
}

.hide-scrollbar {
  overflow: auto;
  height: 470px;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.order-type-dinein:hover {
  background-color: #FF6260 !important;
  color: #fff !important;
}

.order-type-delivery:hover {
  background-color: #1890FF !important;
  color: #fff !important;
}

.order-type-takeaway:hover {
  background-color: #003366 !important;
  color: #fff !important;
}